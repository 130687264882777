$marginSize: 24px;

.mat-snack-bar-container.notification {
  border-radius: 4px;
  margin: $marginSize !important;
  max-width: calc(100vw - (2 * #{$marginSize})) !important;
  width: calc(100vw - (2 * #{$marginSize}));
  color: #ffffff;
  position: fixed;
  top: 0;

  .mat-simple-snackbar-action {
    .mat-button-wrapper {
      color: #333;
    }
  }

  &.success {
    color: #155724;
    background: #d4edda; 
  }
  &.info {
    color: #004085; 
    background: #cce5ff;
  }
  &.warning {
    color: #856404;
    background-color: #fff3cd;
  }
  &.error {
    color: #721c24;
    background-color: #f8d7da;
  }
}