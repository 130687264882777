// Vertical center
@mixin center-vertically {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

// Display block icon
@mixin icon($name)  {
    display: inline-block;
    min-width: 5px;
    min-height: 5px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url($images-path + $name);
}

// Background image
@mixin img($file) {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url($images-path + $file);
}

// IE10 IE11 only
@mixin ieonly() {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      @content;
    }
}

// Firefox only
@mixin ffonly() {
    @-moz-document url-prefix() {
        @content;
    }
}


// DOM element with fixed ratio - 100% width
@mixin imgRatio($x, $y) {
    display: block;
    width: 100%;
    padding-top: ($y/$x)*100%;
    background-size: cover;
    background-position: center;
    background-color: $grey-9;
}
