// Import fonts here
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700;900&display=swap');

@font-face {
  font-family: 'Futura';
  src: url('/assets/fonts/FuturaCE-Medium.woff2') format('woff2'), url('/assets/fonts/FuturaCE-Medium.woff') format('woff'),
    url('/assets/fonts/FuturaCE-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Futura';
  src: url('/assets/fonts/FuturaCE-Bold.woff2') format('woff2'), url('/assets/fonts/FuturaCE-Bold.woff') format('woff'),
    url('/assets/fonts/FuturaCE-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
